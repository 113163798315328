import React, { useRef } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TreeImg from "../images/tree.png";
import pageData from "../data/indexData";
import rightArrowImg from "../images/arrow.png";

const IndexPage = () => {
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const bannerRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  return (
    <Layout>
      <SEO title={"Home"} description={pageData.short_desc} />
      <div className="banner " ref={bannerRef}>
        <div className="tree">
          <div className="d-block d-md-none pt-5"></div>
          <div className="row text-center px-0">
            <div className="intro col-lg-6 px-5 d-none d-lg-flex ">
              <h1 className="mb-4">{pageData.name}</h1>
              <p>{pageData.ceres_header}</p>
            </div>
            <div className="col-lg-6">
              <img className="tree_image" src={TreeImg} alt="ceres tree" />
            </div>
            <div className="intro col-lg-6 p-5 d-flex d-lg-none ">
              <h1 className="mb-4">{pageData.name}</h1>
              <p>{pageData.ceres_header}</p>
            </div>
          </div>
        </div>
        <div onClick={executeScroll} className="scroll-down d-none ">
          <span></span>
          {/* <img src={scrollDownImg} alt='Scroll Down' /> */}
        </div>
        <div onClick={executeScroll} className="scroll-down-text d-none ">
          Scroll Down{/* <img src={scrollDownImg} alt='Scroll Down' /> */}
        </div>
      </div>

      <div className=" bg-dark-content py-5" ref={myRef}>
        <div className="container py-5">
          <h2>{pageData.name}</h2>
          <div className="line my-4"></div>
          <p className="lead">{pageData.short_desc}</p>
        </div>
      </div>

      <div className="bg-content-light py-5">
        <div className=" container py-5">
          <p className="lead">{pageData.long_desc_1}</p>

          <p className="lead">{pageData.long_desc_2}</p>
        </div>
        <div className="container grid">
          <div className="row">
            {pageData.products.map((x) => (
              <div className="p-2 col-md-6 col-lg-4" key={x.name}>
                <div className="product ">
                  <div className="p-4">
                    <div className="icon ">
                      <img
                        className="technology_img"
                        alt={x.name}
                        src={x.img}
                      />
                    </div>
                    <h2 className="my-4">{x.displayName}</h2>
                    <p>{x.desc}</p>
                    <Link to={x.name}>
                      <div className="arrow">
                        <img
                          src={rightArrowImg}
                          alt="navigate"
                          className="pr-4"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" ceres_accuracy">
        <div className="row container  text-center  mx-auto py-5 px-0">
          {pageData.ceres_benefits.map((x, i) => (
            <div className="col-lg-3 col-md-6 py-5 px-0" key={i}>
              <div className="title">{x.desc}</div>
              <div className="benefit">{x.benefit}</div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
